import { useStore } from "vuex";
import { computed } from "vue";

const checkPermission = permission => {
  const store = useStore();
  const currentPermissions = computed(() => store.getters.getPermissions);
  const permissions = computed(() => currentPermissions.value );

  return {
    isGranted: permissions.value.includes(permission),
  };
};

const checkRole = roles => {
  const store = useStore();
  const currentRoles = computed(() => store.getters.getRoles);
  const role = computed(() => currentRoles.value );

  let granted = false;

  for (let index = 0; index < role.value.length; index++) {
    if (roles.includes(role.value[index])) {
      granted = true;
    }
  }

  return {
    isGranted: granted,
  };
};

export { checkPermission, checkRole };