
import { defineComponent, reactive, ref, watch } from "vue";
import useChannelDetail from "@/core/services/compositions/channel/useChannelDetail";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { translate } from "@/core/helpers/translate";
import {
  showErrorPopup,
  showSuccessPopup,
  useQuery,
} from "@/core/helpers/common-helper";
import moment from 'moment';
import { checkPermission } from '@/router/authentication';
import ApiService from "@/core/services/ApiService";

interface EditChannel {
  name: string;
  pool_account: Array<string>;
}

export default defineComponent({
  setup() {
    // init
    const { t } = useI18n();
    const route = useRoute();
    const dialogChanneltVisible = ref(false);
    const submitButton = ref<null | HTMLButtonElement>(null);
    const { query } = useQuery();

    // breadcrumb
    setNewPageBreadcrumbs(t("channel.detail.title"), [
      { title: t("channel.detail.title") }
    ]);

    const editChannel = reactive<EditChannel>({
      name: '',
      pool_account: [],
    });

    const showDialogChannel = () => {
      dialogChanneltVisible.value = true;
    }

    const channelId = route.params.id;

    const { data: detail } = useChannelDetail(channelId);

    watch(detail, (response) => {
      if (response?.rc == 'SUCCESS') {
        editChannel.name = response?.data.name;
        editChannel.pool_account = response?.data.pool_account;
      }
    });

    const submitUpdate = () => {
      dialogChanneltVisible.value = false;

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      if(typeof editChannel.pool_account == 'string') {
        editChannel.pool_account = String(editChannel.pool_account).split(',');
      }

      const payload = {
        name: editChannel.name,
        pool_account: editChannel.pool_account,
      };

      ApiService.setHeader();
      ApiService.put(
        `app/entity/channel/${channelId}`,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Update Status',
            text: 'Success'
          });


        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            for (const key in response.data.data) {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.data[key],
                message: response.data.data[key],
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    return {
      moment,
      checkPermission,
      detail,
      translate,
      showDialogChannel,
      submitUpdate,
      submitButton,
      dialogChanneltVisible,
      editChannel
    };
  }
});
